import { useMemo } from "react";

import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider as MUIThemeProvider } from "@mui/material/styles";
import { ThemeProvider as MUIThemeProviderMuiStyle } from "@mui/styles";
import { palette } from "./palette";
import { shadows } from "./shadows";
import { overrides } from "./overrides";
import { typography } from "./typography";
import { customShadows } from "./custom-shadows";

// ----------------------------------------------------------------------

export default function ThemeProvider({ children }) {
  const memoizedValue = useMemo(
    () => ({
      palette: palette(),
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
      shape: { borderRadius: 8 }
    }),
    []
  );

  const theme = createTheme(memoizedValue);

  theme.components = overrides(theme);

  return (
    <MUIThemeProvider theme={theme}>
      {/* MUIThemeProviderMuiStyle is required to get theme.spacing() inside makeStyles(), else it throws error. Refer: https://stackoverflow.com/a/74121000  */}
      <MUIThemeProviderMuiStyle theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProviderMuiStyle>
    </MUIThemeProvider>
  );
}
