import { memo, forwardRef } from "react";
import Box from "@mui/material/Box";

const Scrollbar = forwardRef(({ children, sx }, ref) => (
  <Box ref={ref} sx={{ overflow: "auto", ...sx }}>
    {children}
  </Box>
));

export default memo(Scrollbar);
