import Box from "@mui/material/Box";

export default function Logo({ src, sx }) {
  return (
    <Box
      component="img"
      src={src || "/assets/images/dudes_logo.png"}
      sx={{ width: 40, height: 40, cursor: "pointer", ...sx }}
    />
  );
}
