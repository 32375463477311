import { createMongoAbility } from "@casl/ability";

export let ability = null;

export function createAbility(rules = []) {
  if (rules && !ability) {
    ability = createMongoAbility(rules);
  }

  return ability;
}
