"use client";

import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import Avatar from "@mui/material/Avatar";
import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";
import { usePathname, useRouter } from "next/navigation";
import useAuth from "@lib/firebase/auth/authContext";
import { getFullName } from "@utils/stringUtils";

import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import navConfig from "./config-navigation";
import { NAV } from "./config-layout";
import Logo from "../common/logo";
import Scrollbar from "../common/scrollbar";
import { useResponsive } from "../../lib/hooks/use-responsive";

export default function Nav({ openNav, onCloseNav }) {
  const pathname = usePathname();
  const { getSession } = useAuth();
  const { role, firstName, middleName, lastName } = getSession();
  const upLg = useResponsive("up", "lg");

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column"
        }
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Logo sx={{ ml: 2 }} />
        {/* Render Account */}
        <Box
          sx={{
            my: 1,
            mx: 1,
            py: 1,
            px: 1.5,
            display: "flex",
            borderRadius: 1.5,
            alignItems: "center",
            width: "50vw",
            bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12)
          }}
        >
          <Avatar alt="photoURL" />

          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2">{getFullName({ firstName, middleName, lastName })}</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {role.charAt(0).toUpperCase() + role.slice(1)}
            </Typography>
          </Box>
        </Box>
      </div>

      {/* Render Menu */}
      <Stack component="nav" spacing={0.5} sx={{ px: 2 }}>
        {navConfig.map((item) => {
          if (item.children) {
            return <NavItem key={item.title} item={item} collapsable />;
          }
          return <NavItem key={item.title} item={item} />;
        })}
      </Stack>

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.WIDTH }
      }}
    >
      {upLg
        ? (
          <Box
            sx={{
              height: 1,
              position: "fixed",
              width: NAV.WIDTH,
              borderRight: (theme) => `dashed 1px ${theme.palette.divider}`
            }}
          >
            {renderContent}
          </Box>
        )
        : (
          <Drawer
            open={openNav}
            onClose={onCloseNav}
            PaperProps={{ sx: { width: NAV.WIDTH } }}
          >
            {renderContent}
          </Drawer>
        )}
    </Box>
  );
}

function NavItem({ item, collapsable = false }) {
  const pathname = usePathname();
  const router = useRouter();
  const [open, setOpen] = useState(false);

  const active = item.path === pathname;

  const handleClick = () => {
    if (collapsable) {
      setOpen(!open);
    } else {
      router.push(item.path);
    }
  };

  return (
    <div>
      <ListItemButton
        sx={{
          minHeight: 44,
          borderRadius: 0.75,
          typography: "body2",
          color: "text.primary",
          textTransform: "capitalize",
          fontWeight: "fontWeightMedium",
          ...(active && {
            color: "primary.main",
            fontWeight: "fontWeightSemiBold",
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.24),
            "&:hover": { bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16) }
          })
        }}
        onClick={handleClick}
      >
        <Box component="span" sx={{ width: 24, height: 24, mr: 2, ...(active && { color: (theme) => theme.palette.primary.main }) }}>
          {item.icon}
        </Box>

        <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
          <Box sx={{ flexGrow: 1 }} component="div">
            {item.title}
            {" "}
          </Box>
          {collapsable
          && (
            open ? <ExpandLess /> : <ExpandMore />
          )}
        </div>
      </ListItemButton>
      {collapsable
        && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Stack spacing={0.5} sx={{ pl: 2.5 }}>
              {item.children.map((child) => (
                <NavItem key={child.title} item={child} />
              ))}
            </Stack>
          </Collapse>
        )}
    </div>

  );
}
