export const capitalizeFirstLetter = (str = "") => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
};

export const sentenceCase = (str = "") => {
  if (str) {
    return str.charAt(0).toUpperCase() + (str.slice(1) || "").toLowerCase();
  }
  return str;
};

export const getSingleLineAddress = (address) => {
  const {
    floorNo, floorNumber, houseNo, houseNumber,
    apartmentOrStreet, landmark, city, pin
  } = address || {};
  return `Floor: ${floorNumber || floorNo}, ${houseNumber || houseNo}, ${apartmentOrStreet}, ${landmark}, ${city}, ${pin}`;
};

export const getFullName = ({ firstName, middleName, lastName }) => (`${firstName || ""} ${middleName || ""} ${lastName || ""}`.replace(/\s+/g, " ").trim());

export const parseCamelCase = (input) => input
  .replace(/([a-z])([A-Z])/g, "$1 $2")
  .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
  .replace(/\b\w/g, (match) => match.toUpperCase());

export const snakeToCamel = (str) => str.toLowerCase().replace(/([-_][a-z])/g, (group) => group
  .toUpperCase()
  .replace("-", "")
  .replace("_", ""));

export function camelCaseToWords(str) {
  const result = str.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function toKebabCase(str = "") { // Ref: https://github.com/huynhsamha/js-convert-case/blob/master/src/modules/js-kebabcase/index.ts
  if (!str) return "";

  return String(str)
    .replace(/^[^A-Za-z0-9]*|[^A-Za-z0-9]*$/g, "")
    .replace(/([a-z])([A-Z])/g, (m, a, b) => `${a}_${b.toLowerCase()}`)
    .replace(/[^A-Za-z0-9]+|_+/g, "-")
    .toLowerCase();
}
