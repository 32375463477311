"use client";

import React, { createContext, useContext, useEffect, useMemo } from "react";
import { getAuth, onAuthStateChanged, signInWithCustomToken } from "firebase/auth";
import toast from "react-hot-toast";
import { queryOpenApi } from "@utils/apiResolvers";
import { useAtom } from "jotai";
import { createAbility } from "@lib/access/ability";
import { signIn, signOutUser } from "./authHelpers";
import { userSession } from "../../globalState";

const AuthContext = createContext({
  signInUser: () => {},
  signOutUser: () => {},
  getSession: () => {}
});

// Export the provider as we need to wrap the entire app with it
export function AuthProvider({ children }) {
  const [session, setSession] = useAtom(userSession);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (userInfo) => {
      if (userInfo) {
        userInfo.getIdToken().then(async (idToken) => {
          if (idToken) {
            const result = await queryOpenApi({
              queryTemplate: "verify_admin_user_login",
              queryJsonParams: { idToken }
            });
            if (result?.hasError === false) {
              await signInWithCustomToken(auth, result.data.authToken);
              setSession(result.data.userInfo);
              if (result.data?.userInfo?.accessConfig) {
                createAbility(result.data?.userInfo?.accessConfig);
              }
              toast.success(result.message, { position: "top-right" });
            } else if (result?.message) {
              toast.error(result.message, { position: "top-right" });
            }
          } else {
            setSession(null);
            toast.error("Request failed, try again", { position: "top-right" });
          }
        });
      } else {
        setSession(null);
      }
    });
    return unsubscribe; // This will let it clean up when it unmounts the view
  }, []);

  function getSession() {
    return session;
  }

  // Make the provider update only when it should.
  // We only want to force re-renders if the authSession,
  // loading or error states change.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly! Even in this case, where
  // you only get re-renders when logging in and out
  // we want to keep things very performant.
  const memoedValue = useMemo(
    () => ({
      signInUser: signIn,
      signOutUser,
      getSession
    }),
    [session]
  );

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <AuthContext.Provider value={memoedValue}>
      {children}
    </AuthContext.Provider>
  );
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth() {
  return useContext(AuthContext);
}
