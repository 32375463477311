import Box from "@mui/material/Box";
import { useResponsive } from "../../lib/hooks/use-responsive";
import { NAV, HEADER } from "./config-layout";

const SPACING = 8;

export default function Main({ children, sx }) {
  const lgUp = useResponsive("up", "lg");

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: "flex",
        flexDirection: "column",
        py: `${HEADER.H_MOBILE + SPACING}px`,
        ...(lgUp && {
          px: 2,
          py: `${HEADER.H_DESKTOP + SPACING}px`,
          width: `calc(100% - ${NAV.WIDTH}px)`
        }),
        ...sx
      }}
    >
      {children}
    </Box>
  );
}
