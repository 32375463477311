"use client";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { alpha, useTheme } from "@mui/material/styles";
import Logo from "../common/logo";
import Iconify from "../common/iconify";
import useAuth from "../../lib/firebase/auth/authContext";

export default function LoginView() {
  const theme = useTheme();
  const { signInUser } = useAuth();

  return (
    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
      <Card
        sx={{
          p: 5,
          width: 1,
          maxWidth: 420,
          height: { xs: 0.42, sm: 0.42, md: 0.47, lg: 0.47, xl: 0.47 }
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Logo src="assets/images/dudes_logo.png" sx={{ width: 70, height: 70 }} />
        </Box>

        <Typography variant="h4" style={{ textAlign: "center", marginTop: 20 }}>Welcome to Dudes</Typography>

        <Typography variant="body2" sx={{ mt: 2, mb: 5, textAlign: "center" }}>
          Admin Control Panel
        </Typography>

        <Button
          fullWidth
          size="large"
          color="inherit"
          variant="outlined"
          sx={{ borderColor: alpha(theme.palette.grey[500], 0.16) }}
          startIcon={<Iconify icon="flat-color-icons:google" />}
          onClick={signInUser}
        >
          Continue with Google
        </Button>
      </Card>
    </Box>
  );
}
