"use client";

import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Toaster } from "react-hot-toast";
import { useAtom } from "jotai";
import { DevTools } from "jotai-devtools";
import { userSession } from "@lib/globalState";
import { AbilityContext } from "@lib/access/abilityContext";
import { ability } from "@lib/access/ability";
import dynamic from "next/dynamic";
import ThemeProvider from "../theme/index";
import DashboardLayout from "../components/mainLayout/index";
import { useScrollToTop } from "../lib/hooks/use-scroll-to-top";
import LoginView from "../components/login/index";
import { AuthProvider } from "../lib/firebase/auth/authContext";
import "react-awesome-lightbox/build/style.css";

const Notification = dynamic(() => import("../lib/firebase/fcm/notification"), { ssr: false }); // Lazy loading required as this component uses window & navigator, and both shall be only available once loaded in browser

export default function RootLayout({ children /* , pageProps */ }) {
  useScrollToTop();
  const [session] = useAtom(userSession);

  return (
    <html lang="en">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <body>
          <ThemeProvider>
            <AuthProvider>
              {
                !session?.email
                  ? <LoginView />
                  : (
                    <AbilityContext.Provider value={ability}>
                      <DashboardLayout>
                        {children}
                        <DevTools />
                      </DashboardLayout>
                    </AbilityContext.Provider>
                  )
              }
            </AuthProvider>
          </ThemeProvider>
          <Toaster />
          <Notification />
        </body>
      </LocalizationProvider>
    </html>
  );
}
