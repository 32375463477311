import { useState, createContext } from "react";
import Box from "@mui/material/Box";
import Nav from "./nav";
import Main from "./main";
import Header from "./header";

export const NavIndex = createContext(1);

export default function DashboardLayout({ children }) {
  const [openNav, setOpenNav] = useState(false);
  const [current, setCurrent] = useState(1);

  const setNavTab = (index) => {
    setCurrent(index);
  };

  return (
    <Box sx={{ width: "100%", height: "100vh", display: "flex", flexDirection: "column" }}>
      <NavIndex.Provider value={current}>
        <Header onOpenNav={() => setOpenNav(true)} />
        <Box sx={{ minHeight: 1, display: "flex", flexDirection: { xs: "column", lg: "row" } }}>
          <Nav openNav={openNav} onCloseNav={() => setOpenNav(false)} setNavTab={setNavTab} />
          <Main>{children}</Main>
        </Box>
      </NavIndex.Provider>
    </Box>
  );
}
